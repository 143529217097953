.Menu{
    /* position: absolute; */
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;

    /* align-self: center; */
    width: 100%;
    /* margin: auto; */
    /* height: 80px; */
    background-color: rgba(255, 255, 255);
    z-index: 10;
    padding: 10px 0 0px;
    font-family:serif;
    max-width: 2500px;
    align-items: center;
    /* justify-content:center; */
    justify-content: space-between;
    
   
  
}
.logo{
    /* display: inline-block; */
    /* position: absolute; */
    position: relative;
    /* font-family:serif; */
    /* z-index: 11; */
    right:10vw;
    /* top:20px; */
    font-size: 1em;
    /* text-align: right; */
    float: right;
    /* rotate: 90deg; */
    /* transform: rotate(10deg); */

}

/* .logo h1,p{
padding:0;
margin:0;

} */

h1{
  /* padding-right: 10%; */
  font-weight: lighter;
  font-size: 2.5em;
}

nav{
    margin-top: 10px;
    /* padding-left: 10%; */
}
ul{
    list-style: none;
    margin:0;
    padding: 0 ;
    /* width: 100%; */
    /* position: absolute; */
    z-index: 10;
    /* display: inline-block; */
  }
  
  li{
    margin:10px 10vw;
    /* display: inline-block; */
    font-size: 1em;
    /* font-weight: bold; */
  
  }
  
  a{
    text-decoration: none;
    color: rgb(0, 0, 0);
    transition: 2s;
    
  /* margin-bottom: 15px; */
    
  }

  li a{
    /* box-shadow: 
    1px 2px 2px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 5px; */
  padding:2px 2px;
  /* border: 2px solid white; */
  margin: 0 -2px;
  /* text-decoration: underline black; */
    
  /* border-radius: 5px; */
  }

  a.menu-active{
    /* color:rgb(31, 26, 26); */
    /* background-color: rgb(32, 32, 32); */
    transition: 2s;
    /* outline: rgb(255, 255, 255) solid 4px; */
  }
  
  a:hover{
    color: rgb(155, 0, 0);
  }

  li a:hover,
  .active{
    /* box-shadow: inset 4px 4px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(255, 255, 255, 0.5); */
     background-color: rgb(32, 32, 32);
    color: rgb(255, 255, 255);
    /* border: 2px solid black; */
    /* outline: rgb(32, 32, 32) solid 4px; */
    transition: 2s;
    
  }


  /* a.menu-active h1{
    color:rgb(146, 0, 0);
    background-color: rgb(32, 32, 32);
    transition: 2s;
  }
  a.menu-active p{
    color:rgb(146, 0, 0);
    background-color: rgb(32, 32, 32);
    transition: 2s;
  } */