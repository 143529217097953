.App {
  /* text-align: center; */
  
}

::selection {
  color: #302626;
  background: #9aaa9f;
}

.canvasContainer{
  display: flex;
  position: absolute;
  left:0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-color: slategrey; */
  margin: 0;
  padding: 0;
  /* z-index: 10; */
}

.fxContainer{
  /* display: flex; */
  height: 80vw;
  width: 100%;
  /* position: relative; */
}

.onmesh{
  cursor: pointer;
}
.offmesh{
  cursor: default ;
}

.textContainer{
  display: flex;
  position: relative;
  vertical-align: middle;
  justify-content: center;
  width: 100vw;
  height: 100%;
}
.picDescription{
  display: inline-block;
  position: absolute;
  /* left:50%; */
  /* justify-content: center; */
  /* vertical-align: middle; */
  text-align: center;
  top: 77vh;
  /* left: 20%; */
  /* margin: 0 auto; */
  /* width: 500px; */
  /* vertical-align: middle; */
  font-size: 8px;
}


.ErroPage{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  
  width: 100vw;
  height: 100vh;
  
}

.ErroPage h1{
  position: absolute;
  color: rgb(197, 197, 197);
  /* background-color: black; */
}

footer{
  font-size: 0.6rem;
  /* margin-left: 10%; */
  left: 10%;
  color: rgb(54, 54, 54);
  height: 20px;
  padding: 0 2px;
  flex-shrink: 0;
  z-index: 1;
  position: fixed;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content:center;
  border-radius: 1px;
  

  bottom: 0px;
}